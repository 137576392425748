import { ReactNode } from 'react';
import { ReactComponent as Instagram } from 'core/common/assets/images/reviews/instagram.svg';
import user1 from 'core/common/assets/images/reviews/user1.png';
import user2 from 'core/common/assets/images/reviews/user2.png';
import user3 from 'core/common/assets/images/reviews/user3.png';
import user4 from 'core/common/assets/images/reviews/user4.png';
import Image from '../Image';
import {
  Container,
  Date,
  Info,
  Item,
  List,
  Name,
  Quotes,
  Text,
  TextContainer,
  Wrapper,
} from './styled';

const reviewsList: Reviews[] = [
  {
    img: <Image src={user1} width={45} height={45} alt="@ria._.panwar" />,
    name: '@ria._.panwar',
    date: '09/17/2021',
    text:
      'Nebula Horoscope tells realistic facts about day to day life, which can be easily relatable. ' +
      'It shows direction.',
    label: <Instagram title="instagram" />,
  },
  {
    img: <Image src={user2} width={45} height={45} alt="@jp63_" />,
    name: '@jp63_',
    date: '09/29/2021',
    text: 'It makes me feel safe, seeing, warm and smart.',
    label: <Instagram title="instagram" />,
  },
  {
    img: <Image src={user3} width={45} height={45} alt="@therealslimmazi" />,
    name: '@therealslimmazi',
    date: '09/28/2021',
    text: 'I love that we have the “my profile” option. I love learning about myself.',
    label: <Instagram title="instagram" />,
  },
  {
    img: <Image src={user4} width={45} height={45} alt="@aarmstrong623" />,
    date: '10/02/2021',
    name: '@aarmstrong623',
    text: 'It gives insight of what is going on in my life. I just love the app 💜💜💜',
    label: <Instagram title="instagram" />,
  },
];

type Reviews = {
  img: ReactNode;
  date: string;
  name: string;
  text: string;
  label?: ReactNode;
};

type ReviewsRealProps = {
  list?: Reviews[];
  className?: string;
};

const ReviewsReal = ({ list, className = '' }: ReviewsRealProps) => {
  const reviews = list || reviewsList;

  return (
    <List className={className}>
      {reviews.map(({ img, name, text, date, label = null }) => (
        <Item key={name}>
          <Wrapper>
            <Container>
              {img}
              <Info>
                <Name>{name}</Name>
                <Date>{date}</Date>
              </Info>
            </Container>
            {label}
          </Wrapper>
          <TextContainer>
            <Quotes title="quotes" />
            <Text>{text}</Text>
          </TextContainer>
        </Item>
      ))}
    </List>
  );
};

export default ReviewsReal;
