import styled from 'styled-components';
import starSvg from 'core/common/assets/images/star.svg';

export const RatingComponent = styled.span<{ percent: number }>`
  width: 100%;
  max-width: 5em;
  height: 1em;
  font-size: 1rem;
  background: ${(props) => `linear-gradient(90deg, #fc0 ${props.percent}%, #eee 0)`};
  mask-image: url(${starSvg});
  mask-position: 0 0;
  mask-size: 1em 1em;
`;
