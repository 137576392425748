import { useConfig } from 'core/common/hooks';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { AddressText, AddressWrapper } from './styled';

export type AddressProps = {
  className?: string;
};

const Address = ({ className }: AddressProps) => {
  const config = useConfig();

  return (
    <AddressWrapper className={className}>
      <Location>
        <LocationOption option={Locales.AE}>
          <AddressText>FT SICH FZCO, Dubai, United Arab Emirates</AddressText>
        </LocationOption>
        <LocationOption option={Locales.US}>
          <AddressText>Nevada, USA</AddressText>
        </LocationOption>
        <LocationOption option={Locales.OTHER}>
          <AddressText>
            {config.companyName}, {config.address}
          </AddressText>
        </LocationOption>
      </Location>
    </AddressWrapper>
  );
};

export default Address;
