import { ReactNode } from 'react';
import { AttentionIcon, Wrapper } from './styled';

type ErrorProps = {
  children: ReactNode;
  className?: string;
};

const Error = ({ children, className = '' }: ErrorProps) => {
  return (
    <Wrapper className={className}>
      <AttentionIcon />
      {children}
    </Wrapper>
  );
};

export default Error;
