import Image from 'next/image';
import styled from 'styled-components';

export const ImageStyled = styled(Image)`
  ${({ fill }) =>
    !fill &&
    `
    height: auto;
  `}
`;
