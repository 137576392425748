import styled from 'styled-components';

export const AddressWrapper = styled.div`
  z-index: 2;
  margin-right: auto;
  margin-left: auto;
`;

export const AddressText = styled.p`
  padding-bottom: 8px;
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.colors.typography500};
  pointer-events: none;
  user-select: none;
`;
