export const useWidgetBot = () => {
  const getIsWidgetLoaded = () => !!window.zE;

  const callWidget = (...args: Array<unknown>) => {
    if (!window.zE) return;

    window.zE.apply(null, args);
  };

  const openWidget = () => {
    callWidget('messenger', 'open');
  };

  const closeWidget = () => {
    callWidget('messenger', 'close');
  };

  const addWidgetTags = (tags: Array<string>) => {
    callWidget('messenger:set', 'conversationTags', tags);
  };

  const subscribeOnCloseWidget = (callback?: () => void) => () => {
    callWidget('messenger:on', 'close', () => {
      if (callback) {
        callback();
      }
    });
  };

  const subscribeOnOpenWidget = (callback?: () => void) => () => {
    callWidget('messenger:on', 'open', () => {
      if (callback) {
        callback();
      }
    });
  };

  return {
    getIsWidgetLoaded,
    openWidget,
    closeWidget,
    addWidgetTags,
    subscribeOnCloseWidget,
    subscribeOnOpenWidget,
  };
};
