import { ReactNode } from 'react';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { RenderButtonProps, SupportBotWidget } from 'core/common/components/SupportBotWidget';
import { SupportBotWrapperStyled, ButtonStyled, QuestionIconStyled } from './styled';

type SupportBotButtonProps = {
  className?: string;
  buttonText?: ReactNode;
  variant?: 'fixed' | 'static';
};

export const SupportBotButton = ({
  className = '',
  buttonText,
  variant = 'static',
}: SupportBotButtonProps) => {
  const renderButton = ({ onOpen }: RenderButtonProps) => {
    return (
      <ButtonStyled onClick={onOpen}>
        <QuestionIconStyled />
        {buttonText}
      </ButtonStyled>
    );
  };

  return (
    <RenderOnlyOnClient>
      <SupportBotWrapperStyled variant={variant} className={className}>
        <SupportBotWidget renderButton={renderButton} />
      </SupportBotWrapperStyled>
    </RenderOnlyOnClient>
  );
};

export default SupportBotButton;
