import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'core/common/assets/images/info.svg';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.typography700};
  background-color: rgba(235, 87, 87, 0.15);
`;

export const AttentionIcon = styled(InfoIcon)`
  width: 20px;
  height: 20px;
  margin-right: 4px;
  transform: scaleY(-1);

  path,
  rect {
    fill: ${({ theme }) => theme.colors.error};
  }
`;
