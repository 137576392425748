import styled from 'styled-components';
import { ReactComponent as QuotesImage } from 'core/common/assets/images/reviews/quotes.svg';

export const List = styled.ul`
  width: 100%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 706px;
  }
`;

export const Item = styled.li`
  flex-grow: 1;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid ${(props) => props.theme.colors.typography200};
  border-radius: 20px;
  background: white;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 328px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  display: flex;
`;

export const Info = styled.div`
  margin-left: 10px;
`;

export const Name = styled.p`
  margin-bottom: 2px;
  font-weight: 600;
  line-height: 130%;
  color: ${(props) => props.theme.colors.info};
`;

export const Date = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.typography500};
`;

export const Quotes = styled(QuotesImage)`
  flex-shrink: 0;
`;

export const TextContainer = styled.div`
  display: flex;
`;

export const Text = styled.p`
  margin-left: 10px;
  font-style: italic;
  line-height: 140%;
`;
