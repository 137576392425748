import { ImageProps as NextImageProps } from 'next/image';
import { ImageStyled } from './styled';

export type ImageProps = NextImageProps;

const Image = (props: ImageProps) => {
  return <ImageStyled {...props} />;
};

export default Image;
