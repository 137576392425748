import { RatingComponent } from './styled';

type RatingProps = Partial<{
  percent: number;
  className: string;
}>;

const Rating = ({ className, percent = 100 }: RatingProps) => {
  return <RatingComponent className={className} percent={percent} />;
};

export default Rating;
