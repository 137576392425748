import { ReactNode, useEffect, useState } from 'react';
import Script from 'next/script';
import { LoadingStatuses } from 'core/common/entities';
import { useConfig } from 'core/common/hooks';
import { useWidgetBot } from './useWidgetBot';

export type RenderButtonProps = {
  onOpen: () => void;
};

type SupportBotWidgetProps = {
  renderButton: (props: RenderButtonProps) => ReactNode;
  isAutoOpen?: boolean;
  onLoadFinish?: () => void;
  onLoadFail?: () => void;
  onClose?: () => void;
  onOpen?: () => void;
};

export const SupportBotWidget = ({
  isAutoOpen = false,
  onLoadFinish,
  onClose,
  onLoadFail,
  onOpen,
  renderButton,
}: SupportBotWidgetProps) => {
  const {
    supportBot: { url, tags },
  } = useConfig();
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.IDLE);
  const {
    openWidget,
    closeWidget,
    addWidgetTags,
    subscribeOnCloseWidget,
    subscribeOnOpenWidget,
    getIsWidgetLoaded,
  } = useWidgetBot();

  const open = () => {
    if (!getIsWidgetLoaded()) {
      return;
    }
    openWidget();
  };

  const close = () => {
    if (!getIsWidgetLoaded()) {
      return;
    }
    closeWidget();
  };

  const subscribeOnClose = subscribeOnCloseWidget(() => {
    if (onClose) {
      onClose();
    }
  });

  const subscribeOnOpen = subscribeOnOpenWidget(() => {
    if (onOpen) {
      onOpen();
    }
  });

  const handleOnLoad = () => {
    setLoadingStatus(LoadingStatuses.FULFILLED);
    if (onLoadFinish) {
      onLoadFinish();
    }
  };

  const handleOnLoadStart = () => setLoadingStatus(LoadingStatuses.PENDING);

  const handleOnError = () => {
    setLoadingStatus(LoadingStatuses.FAILED);
    if (onLoadFail) {
      onLoadFail();
    }
  };

  const initWidget = () => {
    addWidgetTags(tags);
    subscribeOnClose();
    subscribeOnOpen();
    isAutoOpen ? open() : close();
    setLoadingStatus(LoadingStatuses.FULFILLED);
  };

  useEffect(() => {
    if (loadingStatus === LoadingStatuses.FULFILLED || getIsWidgetLoaded()) {
      initWidget();
    }

    return close;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingStatus]);

  return (
    <>
      <Script
        id="ze-snippet"
        strategy="afterInteractive"
        src={url}
        onLoad={handleOnLoad}
        onLoadStart={handleOnLoadStart}
        onError={handleOnError}
      />
      {loadingStatus === LoadingStatuses.FULFILLED && renderButton({ onOpen: open })}
    </>
  );
};
